import { useNavigate } from "react-router-dom";
import picture from "../../Images/Mobile.jpg";
import LeftSideExploreMore from "../../Global/LeftSideExploreMore";
import Service from "../Services/Service";
import TechnologyStack from "../Technologies/OurTechStack";
import Feedback from "../../Global/Feedback";
import OurClient from "../Our Client/OurClient";
import AutoPlay from "../../Global/DCSlider";
function Home() {
  const navigate = useNavigate();

  const handleController = () => {
    navigate("/");
  };
  return (
    <div style={{ width: '100%',overflow:'hidden' }}>
      <AutoPlay />
      <div>
        <Service />
      </div>
      <div className="pt-4 mt-5 pb-5" style={{backgroundColor:"#F2F5F9"}}>
       <LeftSideExploreMore
        handleController={handleController}
        secondbutton={'Lets Get Started'}
        heading="Maximize your business's capabilities with our expert outsourcing offerings."
        subheading="Elevate your in-house team with our carefully selected specialists tailored to each project. Gain access to skilled designers, developers, and project managers, minus the burden of full-time staffing management."
        picture={picture}
      /> 
       </div>
      <OurClient />
      <Feedback />
      <TechnologyStack />
    </div>
  );
}

export default Home;
